<template>
  <div class="cursor-pointer">
    <img
      v-if="useGetThumbnail(customersStore?.currentUserCustomer?.image)"
      :src="useGetThumbnail(customersStore?.currentUserCustomer?.image)"
      alt=""
      class="rounded-x1 object-cover"
      :class="[size ? size : 'w-x6 h-x6']"
    />
    <div
      v-else-if="
        useGetCustomerInitials(customersStore?.currentUserCustomer)?.length &&
        !useGetThumbnail(customersStore?.currentUserCustomer?.image)
      "
      class="flex rounded-x1 items-center justify-center text-xs leading-base-sm bg-grey-500"
      :class="[size ? size : 'w-x6 h-x6']"
    >
      <p class="text-white">
        {{ useGetCustomerInitials(customersStore?.currentUserCustomer) }}
      </p>
    </div>

    <img
      v-else
      src="~/assets/img/default-user.png"
      alt=""
      class="rounded-x1 object-cover"
      :class="[size ? size : 'w-x6 h-x6']"
    />
  </div>
</template>

<script lang="ts" setup>
import { useCustomersStore } from "~/store/customers";

const props = defineProps<{
  size?: string;
}>();

const customersStore = useCustomersStore();
</script>
